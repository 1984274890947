/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import Logo from '@/assets/logo_white.svg';
// import Title from '@/components/Title';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import ContactForm from '@/components/ContactForm/ContactForm';

export default function Home() {
  const [getFormOpen, setFormOpen] = useState(false);
  const toggleForm = () => setFormOpen(!getFormOpen);
  return (
    <main className="flex h-screen text-white bg-gray-800">
      {/* <Title> chrisvaupel.com </Title> */}

      <div className="px-5 pt-6 mx-auto lg:m-auto">
        <div className="text-center mb-9">
          <Logo className="mb-8 " />

          <button
            type="button"
            className="-ml-10 font-bold text-center text-teal-400 "
            onClick={() => toggleForm()}
          >
            <FontAwesomeIcon className="mr-2" icon={faEnvelope} /> Contact me
          </button>

          {getFormOpen && (
            <div className="p-4 mt-4 text-left border-2 border-gray-400 rounded">
              <ContactForm />
            </div>
          )}
        </div>

        <p className="mb-4 font-bold">
          Untill this website is finished, feel free to visit my instagram
          accounts:
        </p>
        <a
          href="https://www.instagram.com/chrisvaupel.visions/"
          rel="nofollow noreferrer"
          target="_blank"
          className="block mb-4 text-teal-400"
        >
          • People photography
        </a>

        <a
          href="https://www.instagram.com/chrisvaupel.views/"
          rel="nofollow noreferrer"
          target="_blank"
          className="block mb-4 text-teal-400"
        >
          • Nature photography
        </a>

        <a
          href="https://www.instagram.com/chrisvaupel.misc/"
          rel="nofollow noreferrer"
          target="_blank"
          className="block mb-4 text-teal-400"
        >
          • Misc. stuff
        </a>

        <a
          href="https://www.instagram.com/chrisvaupel.crafts/"
          rel="nofollow noreferrer"
          target="_blank"
          className="block mb-4 text-teal-400"
        >
          • Crafting props for Live Action Roleplay and photoshoots
        </a>
      </div>
    </main>
  );
}
